@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #ffe1d9;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;
}

::placeholder {
    color: #081588;
}
